import BlogList from './BlogList';
import Header from './Header';
import PostList from './PostList';
import SEO from './SEO';
import TagsBlock from './TagsBlock';
import GA4React from 'ga-4-react'

export { BlogList, Header, PostList, SEO, TagsBlock };

const ga4react = new GA4React(
    'G-5CHFTX88WN',
    { /* ga custom config, optional */ },
    [ /* additional code, optional */ ],
    5000 /* timeout, optional, defaults is 5000 */
    );
    ga4react.initialize().then((ga4) => {
      ga4.pageview('path')
      ga4.gtag('event','pageview','path') // or your custom gtag event
    },(err) => {
      console.error(err)
    })
    